import { ExclusionZone } from 'modules/exclusionZones/types';
import { DownloadedRoute } from '../types';
import { formatIsoTime } from './time';

export const getExclusionZoneLabel = (zones: ExclusionZone[]): string => {
  const disabledZonesLength = zones.filter(({ enabled }) => !enabled).length;
  const enabledZonesLength = zones.length - disabledZonesLength;
  const numberOfZones = enabledZonesLength
    ? `${enabledZonesLength} zone${enabledZonesLength > 1 ? 's' : ''} active`
    : '';
  const numberOfDisabledZones = `${
    numberOfZones ? `, with ${disabledZonesLength}` : `${disabledZonesLength}`
  } disabled zone${disabledZonesLength > 1 ? 's' : ''} ignored`;
  return `Avoid all exclusion zones (${numberOfZones}${
    disabledZonesLength ? numberOfDisabledZones : ''
  })`;
};

export const getRoutingModelRunLabel = (
  routing: DownloadedRoute,
): string | null => {
  if (!routing.metadata?.Internal?.modelRun) return null;

  const modelRun = routing.metadata.Internal.modelRun;
  if (typeof modelRun !== 'string') return null;

  const year = modelRun.slice(0, 4);
  const month = modelRun.slice(4, 6);
  const day = modelRun.slice(6, 8);
  const hour = modelRun.slice(8, 10);
  return `${year}-${month}-${day} ${hour}:00 UTC`;
};

export const getRoutingDetailsLabel = (routing: DownloadedRoute): string => {
  const creationTime = formatIsoTime(routing.metadata?.Internal?.routingTime);
  const startTime = formatIsoTime(
    routing.metadata?.Route?.StartTime ||
      routing.metadata?.Statistics?.startTime,
  );
  const endTime = formatIsoTime(routing.metadata?.Route?.time2finish);
  const distance = Number(
    routing.metadata?.Route?.DistGCL || routing.metadata?.Route?.DistSailed,
  ).toFixed(2);
  const modelRun = getRoutingModelRunLabel(routing);

  return `${
    creationTime !== 'Invalid date' ? `Created: ${creationTime} UTC` : ''
  }<br>Routing start: ${startTime} UTC<br>Routing end: ${endTime} UTC<br>Routing length: ${distance} nm${
    modelRun ? `<br>Weather model run: ${modelRun}` : ''
  }`;
};
