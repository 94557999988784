/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map } from 'mapbox-gl';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point as turfPoint, polygon } from '@turf/helpers';

import useContextMenu from 'modules/main/hooks/useContextMenu';
import useModal from 'modules/main/hooks/useModal';
import { Divider, Modal } from 'modules/ui/components';
import NewRouting from 'modules/routing/components/NewRoutingModal';
import { DownloadMeteogram } from 'modules/meteogram/components';
import {
  currentEcmwfDataSets,
  currentWamDataSets,
  currentNemoDataSets,
  currentGfsDataSets,
} from 'modules/meteo/redux/selectors';
import { addNotification } from 'modules/notifications/redux';
import { removeFullMeteogramInPoint } from 'modules/meteogram/redux';
import { NotificationTypes } from 'modules/notifications';

import useSelectRoutePoints from './useSelectRoutePoints';

import { ContextMenuContainer, MenuItem } from './styledComponents';
import { useSetCustomCurrentLocation } from 'modules/location';

// TODO: move this to dictionary file
const MSG =
  'There is no data available offline for this location. Please use the “Download full meteogram” option instead.';

// TODO: Import this form helper
export const isRelatedPolygon = (box: any, coords: any): boolean => {
  const { maxLat, minLng, minLat, maxLng } = box;
  const point = turfPoint([coords.lng, coords.lat]);
  const poly = polygon([
    [
      [minLng, maxLat],
      [minLng, minLat],
      [maxLng, minLat],
      [maxLng, maxLat],
      [minLng, maxLat],
    ],
  ]);
  return booleanPointInPolygon(point, poly);
};

const isAvailable = (boxes: any, coordinates: any): boolean => {
  return boxes
    .map((b: any) => isRelatedPolygon(b, coordinates))
    .some((e: boolean) => e === true);
};

type Props = {
  map: Map;
};

const ContextMenu: React.FC<Props> = ({ map }) => {
  const dispatch = useDispatch();
  const [isNewRoutingOpen, toggleNewRouting] = useModal(false);
  const [isDownloadFullMeteogram, toggleFullMeteogram] = useModal(false);
  const { xPos, yPos, coordinates, menu } = useContextMenu(map);
  const [isMeteogramAvailable, setAvailability] = useState(false);
  const emfwc = useSelector(currentEcmwfDataSets);
  const wam = useSelector(currentWamDataSets);
  const nemo = useSelector(currentNemoDataSets);
  const gfs = useSelector(currentGfsDataSets);

  const { handleSetContextMenuCoordinates } = useSetCustomCurrentLocation();

  const {
    coordinatesFrom,
    handleRoutFromClick,
    handleRoutToClick,
    setCoordinatesFrom,
  } = useSelectRoutePoints(map, coordinates, toggleNewRouting);

  const showOfflineMeteogram = useCallback(() => {
    if (isMeteogramAvailable) {
      window.open(
        `/meteogram?lat=${coordinates.lat}&lng=${coordinates.lng}`,
        '_blank',
      );
    } else {
      dispatch(
        addNotification({ type: NotificationTypes.Error, message: MSG }),
      );
    }
  }, [coordinates, isMeteogramAvailable, dispatch]);

  const downloadFullMeteogram = useCallback(() => {
    dispatch(removeFullMeteogramInPoint());
    toggleFullMeteogram(true);
  }, [toggleFullMeteogram, dispatch]);

  const closeModal = useCallback(() => {
    toggleNewRouting(false);
    toggleFullMeteogram(false);
    setCoordinatesFrom(null);
  }, [toggleNewRouting, toggleFullMeteogram, setCoordinatesFrom]);

  const handleSetMyBoatPosition = useCallback(() => {
    handleSetContextMenuCoordinates(coordinates.lat, coordinates.lng);
  }, [handleSetContextMenuCoordinates, coordinates]);

  useEffect(() => {
    if (menu) {
      setAvailability(
        isAvailable([...emfwc, ...wam, ...nemo, ...gfs], coordinates),
      );
    }
  }, [emfwc, wam, nemo, gfs, coordinates, menu, setAvailability]);

  if (menu) {
    return (
      <div style={{ position: 'fixed', top: yPos, left: xPos }}>
        <ContextMenuContainer>
          <MenuItem onClick={handleRoutFromClick}>Routing from here</MenuItem>
          <MenuItem onClick={handleRoutToClick}>Routing to here</MenuItem>
          <Divider />
          {false && <MenuItem>New waypoint</MenuItem>}
          <MenuItem
            available={isMeteogramAvailable}
            onClick={showOfflineMeteogram}
          >
            Show meteogram
          </MenuItem>
          <MenuItem onClick={downloadFullMeteogram}>
            Download full meteogram
          </MenuItem>
          {false && <MenuItem>New exclusion zone</MenuItem>}
          {false && <MenuItem>Pivot routing</MenuItem>}
          <Divider />
          <MenuItem onClick={handleSetMyBoatPosition}>
            Set my boat position here
          </MenuItem>
        </ContextMenuContainer>
      </div>
    );
  }
  return (
    <>
      <NewRouting
        isOpen={isNewRoutingOpen}
        coordinatesTo={[coordinates.lng, coordinates.lat]}
        coordinatesFrom={coordinatesFrom}
        closeModal={closeModal}
      />
      {isDownloadFullMeteogram && (
        <Modal
          isOpen={isDownloadFullMeteogram}
          width={520}
          title="Download full meteogram"
          closeModal={closeModal}
          onRequestClose={closeModal}
          isSmall={isDownloadFullMeteogram}
        >
          <DownloadMeteogram
            closeModal={closeModal}
            lat={coordinates.lat}
            lon={coordinates.lng}
          />
        </Modal>
      )}
    </>
  );
};

export default React.memo(ContextMenu);
