import React from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'mapbox-gl';

import { SettingsModal } from 'modules/settings';
import { CollapsibleContainer, Button } from 'modules/ui/components';
import { useModal } from 'modules/main';
import { isOnlineSelector, isUiVisibleSelector } from 'modules/metadata';
import { useDisplayCurrentLocation } from 'modules/location';

import Header from './Header';
import { BoatSideMenuSection } from '../BoatSideMenuSection';
import { ConnectionSideMenuSection } from '../ConnectionSideMenuSection';
import { FleetSideMenuSection } from '../FleetSideMenuSection';

import { ButtonsContainer, OfflineText } from './styledComponents';
import { LocationHeader } from './LocationHeader';

type Props = {
  onModeChange: (value: boolean) => void;
  onVisibilityChange: (value: string | null) => void;
  id: string;
  isOpened: boolean;
  map: Map;
};

const HomeSideMenu: React.FC<Props> = ({
  // onModeChange,
  isOpened,
  onVisibilityChange,
  id,
  map,
}) => {
  const isOnline = useSelector(isOnlineSelector);
  const isUiVisible = useSelector(isUiVisibleSelector);

  useDisplayCurrentLocation(map);

  const showFleetData = true;

  const [
    isSettingsModalOpen,
    ,
    openSettingsModal,
    closeSettingsModal,
  ] = useModal(false);

  return (
    <>
      {isSettingsModalOpen && (
        <SettingsModal
          isOpen={isSettingsModalOpen}
          closeModal={closeSettingsModal}
        />
      )}
      <CollapsibleContainer
        isVisible={isUiVisible}
        opacity={0.8}
        isOpened={isOpened}
        onVisibilityChange={onVisibilityChange}
        id={id}
        title="General"
        RightTitleComponent={
          isOnline ? undefined : <OfflineText>Offline mode</OfflineText>
        }
        // TODO Fleet data
        HeaderComponent={
          <>
            {showFleetData && <Header map={map} />}
            <LocationHeader />
          </>
        }
      >
        {showFleetData && <FleetSideMenuSection />}
        <BoatSideMenuSection addPadding={showFleetData} />
        <ConnectionSideMenuSection />
        <ButtonsContainer>
          {/* <Button
            clickHandler={onlineListenerHandler}
            isSecondary
            label="Send feedback"
          /> */}
          <Button
            stretch
            isSecondary
            clickHandler={openSettingsModal}
            label="Settings"
          />
        </ButtonsContainer>
      </CollapsibleContainer>
    </>
  );
};

export default React.memo(HomeSideMenu);
