import { useCallback } from 'react';
import { Map } from 'mapbox-gl';

import { getGeoJsonFromLocalForage } from 'modules/localForage/utils';
import db from 'modules/localForage/db';

import { DownloadedRoute } from '../types';
import { makeLineFromFeatureCollectionPoints } from 'modules/geojson';
import { addEnsembleProperties, addMidnightProperty } from '../utils/route';
import {
  emptyGeojson,
  RoutingLayers,
  RoutingLayersSources,
} from 'modules/mapLayers';

export const useHandleRoutingSource = (map: Map) => {
  const handleSourceChange = useCallback(
    async (routing: DownloadedRoute, index: number) => {
      if (routing.optimalRouteStorageId && routing.timedOptimalRouteStorageId) {
        const [route, timedRoute] = await Promise.all([
          getGeoJsonFromLocalForage(db.routings, routing.optimalRouteStorageId),
          getGeoJsonFromLocalForage(
            db.routings,
            routing.timedOptimalRouteStorageId,
          ),
        ]);
        try {
          const line = route && makeLineFromFeatureCollectionPoints(route);
          map
            .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(line);
          const modifiedTimedOptimalRoute = addMidnightProperty(timedRoute);
          map
            .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(modifiedTimedOptimalRoute || emptyGeojson);
        } catch (error) {
          if (map) {
            map
              .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
            map
              .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
          }
        }
      }
      if (
        routing.hasPolarSpread &&
        routing.polarSpreadLinesStorageId &&
        routing.polarSpreadPointsStorageId
      ) {
        const [route, timedRoute] = await Promise.all([
          getGeoJsonFromLocalForage(
            db.routings,
            routing.polarSpreadLinesStorageId,
          ),
          getGeoJsonFromLocalForage(
            db.routings,
            routing.polarSpreadPointsStorageId,
          ),
        ]);
        try {
          const modifiedTimedOptimalRoute = addMidnightProperty(timedRoute);
          map
            .getSource(`${RoutingLayersSources.RoutingPolarSpreadLine}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(route || emptyGeojson);
          map
            .getSource(
              `${RoutingLayersSources.RoutingPolarSpreadPoints}${index}`,
            )
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(modifiedTimedOptimalRoute || emptyGeojson);
        } catch (error) {
          map
            .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(emptyGeojson);
          map
            .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(emptyGeojson);
        }
      }
      if (routing.allRoutingStorageId) {
        const allRoutes = await getGeoJsonFromLocalForage(
          db.routings,
          routing.allRoutingStorageId,
        );
        const timedRoute = routing.timedOptimalRouteStorageId
          ? await getGeoJsonFromLocalForage(
              db.routings,
              routing.timedOptimalRouteStorageId,
            )
          : null;
        try {
          const modifiedAllRoutes = addEnsembleProperties(allRoutes);
          const modifiedTimedOptimalRoute = addMidnightProperty(
            timedRoute,
            true,
          );
          // This removes the barbs from the map on source change
          map.setFilter(`${RoutingLayers.TimedOptimalRouteBarbs}${index}`, [
            '==',
            ['id'],
            -1,
          ]);
          map
            .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(modifiedAllRoutes);
          map
            .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .setData(modifiedTimedOptimalRoute || emptyGeojson);
        } catch (error) {
          if (map) {
            map
              .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
            map
              .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              .setData(emptyGeojson);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const clearSources = useCallback(
    async (index: number) => {
      try {
        map
          .getSource(`${RoutingLayersSources.OptimalRoute}${index}`)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(emptyGeojson);
        map
          .getSource(`${RoutingLayersSources.TimedOptimalRoute}${index}`)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .setData(emptyGeojson);
        // remove barbs filter when clearing source
        map.setFilter(`${RoutingLayers.TimedOptimalRouteBarbs}${index}`, null);
      } catch (error) {
        console.error(
          'Error getting source :' +
            `${RoutingLayersSources.OptimalRoute}${index}`,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { handleSourceChange, clearSources };
};
