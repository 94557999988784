import {
  featureCollection,
  lineString,
  Feature,
  LineString,
  Properties,
} from '@turf/helpers';
import { check, HintError } from '@placemarkio/check-geojson';

export const makeEmptyGeojson = (): GeoJSON.FeatureCollection<
  GeoJSON.Geometry
> => ({
  type: 'FeatureCollection',
  features: [],
});

export const mergeGeoJSONs = (
  geoJSONs: GeoJSON.FeatureCollection<GeoJSON.Geometry>[],
): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  const newJson = makeEmptyGeojson();
  for (let i = 0; i < geoJSONs.length; i += 1) {
    newJson.features = [...newJson.features, ...geoJSONs[i].features];
  }
  return newJson;
};

export const makePoint = (
  coordinates: number[],
  properties: GeoJSON.GeoJsonProperties,
): GeoJSON.Feature<GeoJSON.Geometry> => ({
  type: 'Feature',
  properties,
  geometry: { type: 'Point', coordinates },
});

export const makePolygon = (
  coordinates: number[][],
  properties: GeoJSON.GeoJsonProperties,
): GeoJSON.Feature<GeoJSON.Geometry> => ({
  type: 'Feature',
  properties,
  geometry: { type: 'Polygon', coordinates: [coordinates] },
});

export const makeLineFromPointsGeojson = (
  featureColection: GeoJSON.FeatureCollection<GeoJSON.Point>,
  properties: GeoJSON.GeoJsonProperties,
): GeoJSON.Feature<GeoJSON.LineString> => {
  return lineString(
    featureColection.features.map((f) => f.geometry.coordinates),
    properties,
  );
};

export const makeMultiLineFromPointsGeojson = (
  featureColections: GeoJSON.FeatureCollection<GeoJSON.Point>[],
  properties: GeoJSON.GeoJsonProperties[],
): GeoJSON.FeatureCollection<GeoJSON.LineString> => {
  return featureCollection(
    featureColections.map((collection, index) =>
      makeLineFromPointsGeojson(collection, properties[index] || {}),
    ),
  );
};

export const addPropertyToEachFeature = (
  featureColection: GeoJSON.FeatureCollection<GeoJSON.Point>,
  properties: GeoJSON.GeoJsonProperties,
): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  const newJson = makeEmptyGeojson();
  newJson.features = featureColection.features.map((feat) => ({
    ...feat,
    properties: { ...feat.properties, ...properties },
  }));
  return newJson;
};

export const getPropertyFromLastPoint = (
  featureColection: GeoJSON.FeatureCollection<GeoJSON.Point>,
): GeoJSON.GeoJsonProperties => {
  return featureColection.features[featureColection.features.length - 1]
    .properties;
};

export const makeLineFromFeatureCollectionPoints = (
  featureColection: GeoJSON.FeatureCollection<GeoJSON.Geometry>,
): Feature<LineString, Properties> => {
  let prevLng = 0;
  return lineString(
    featureColection.features.map((f) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let lng = f.geometry.coordinates[0] as number;
      lng +=
        // eslint-disable-next-line no-nested-ternary
        lng - prevLng > 180 ? -360 : prevLng - lng > 180 ? 360 : 0;
      prevLng = lng;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return [lng, f.geometry.coordinates[1]];
    }),
  );
};

export const isValidGeojson = (geojson: string): boolean => {
  try {
    check(geojson);
    return true;
  } catch (error) {
    return false;
  }
};

export const getGeojsonValidationError = (geojson: string): string => {
  try {
    check(geojson);
    return '';
  } catch (error: unknown) {
    const isHintError = error instanceof HintError;
    console.log('isHintError', error);
    if (isHintError) {
      return (error as HintError).issues[0].message;
    }
    return "Couldn't parse the GeoJSON file.";
  }
};

export const getValidFeaturesByType = (
  geojson: GeoJSON.FeatureCollection,
  geometryType: GeoJSON.GeoJsonTypes,
): Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] => {
  const validFeatures = geojson.features.filter((feature) => {
    return (
      feature.type === 'Feature' && feature.geometry?.type === geometryType
    );
  });

  return validFeatures;
};
