import React from 'react';

import { H2 } from 'modules/ui/typography';
import { AuthImageContainer } from '../../components';

const RegistrationSuccessUi: React.FC = () => {
  return (
    <AuthImageContainer>
      <H2>
        You have successfully registered for a Dorado account.
        <br /> Please check your email inbox.
      </H2>
    </AuthImageContainer>
  );
};

export const RegistrationSuccess = React.memo(RegistrationSuccessUi);
