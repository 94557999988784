import { Map } from 'mapbox-gl';
import { useCallback } from 'react';

export const useChangeLayerVisibility = (map: Map) => {
  const changeLayerVisibility = useCallback(
    (hide: boolean, layerName: string) => {
      if (hide) {
        map.setLayoutProperty(layerName, 'visibility', 'none');
      } else {
        map.setLayoutProperty(layerName, 'visibility', 'visible');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { changeLayerVisibility };
};
