import React, { useCallback, useState } from 'react';

import { Modal, Button, DragAndDrop, ModalFooter } from 'modules/ui/components';
import {
  getGeojsonValidationError,
  getValidFeaturesByType,
  isValidGeojson,
} from 'modules/geojson';

import { FormContainer, DragAndDropContainer } from './styledComponents';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  goToUpload: (
    values: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[],
  ) => void;
  goToError: (hasMultipleZonesError?: boolean, error?: string) => void;
};

const SelectFileModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  goToUpload,
  goToError,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const onNext = useCallback(async () => {
    if (!file) return;
    try {
      const content = await file.text();
      const isValid = isValidGeojson(content);
      if (!isValid) {
        const error = getGeojsonValidationError(content);
        goToError(false, error);
      } else {
        const parsedContent = JSON.parse(content);
        const validFeatures = getValidFeaturesByType(
          parsedContent,
          'Polygon',
        ) as GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[];
        if (validFeatures.length === 1) {
          goToUpload(validFeatures);
        } else {
          goToError(true);
        }
      }
    } catch (error) {
      goToError();
    }
  }, [file, goToError, goToUpload]);

  return (
    <Modal
      isOpen={isOpen}
      width={600}
      title="Upload GeoJSON with 1 inclusion zone"
      closeModal={closeModal}
    >
      <FormContainer>
        <DragAndDropContainer>
          <DragAndDrop
            accept=".json"
            file={file}
            label="Please upload a valid GeoJSON file from your computer."
            onChange={setFile}
          />
        </DragAndDropContainer>
      </FormContainer>
      <ModalFooter>
        <Button isSecondary label="Cancel" clickHandler={closeModal} />
        <Button disabled={!file} label="Next" clickHandler={onNext} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectFileModal);
