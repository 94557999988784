import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/main/redux';
import { Route, DownloadedRoute } from '../types';

export const serverRoutingsListSelector = (state: RootState): Route[] =>
  state.routing.serverRoutings.list;

export const hasNewServerRoutingForRefreshSelector = (
  state: RootState,
): boolean => state.routing.serverRoutings.hasNew;

export const downloadedRoutesSelector = (
  state: RootState,
): { [key: string]: DownloadedRoute } => state.routing.downloadedRoutings;

export const activeIsochronesStorageKeySelector = (state: RootState): string =>
  state.routing.activeIsochronesStorageKey;

export const sideMenuRoutingIdsSelector = (state: RootState): string[] =>
  state.routing.sideMenuRoutingIds.list.filter(Boolean) as string[];

export const sideMenuRoutingIdsWithIndexSelector = (
  state: RootState,
): { [key: string]: number } =>
  state.routing.sideMenuRoutingIds.list.reduce((acc, id, index) => {
    if (id) {
      acc[id] = index;
    }
    return acc;
  }, {} as { [key: string]: number });

export const activeRoutingsIdsSelector = (state: RootState): string[] =>
  state.routing.activeRoutingsIds;

export const downloadedRoutingByIdSelector = (
  state: RootState,
  id: string,
): DownloadedRoute | undefined => state.routing.downloadedRoutings[id];

export const serverRoutingsListLastRequestTimestampSelector = (
  state: RootState,
): number => state.routing.serverRoutings.lastTimestamp;

export const downloadedRoutingsListSelector = (
  state: RootState,
): DownloadedRoute[] =>
  Object.values(state.routing.downloadedRoutings).filter(
    (r) => r.id !== undefined,
  );

export const sortedDownloadedRoutingsListSelector = createSelector(
  [downloadedRoutingsListSelector],
  (routings) => {
    const sortedRoutings = [...routings];
    sortedRoutings.sort((a, b) =>
      a.metadata?.Statistics?.startTime < b.metadata?.Statistics?.startTime
        ? 1
        : -1,
    );
    return sortedRoutings;
  },
);

export const sortedSideMenuRoutingIdsSelector = createSelector(
  [sortedDownloadedRoutingsListSelector, sideMenuRoutingIdsSelector],
  (routings, ids) =>
    routings.filter((r) => ids.includes(r.id)).map((r) => r.id),
);

export const sideMenuRoutingsListSelector = createSelector(
  [downloadedRoutingsListSelector, sideMenuRoutingIdsSelector],
  (routings, ids) => {
    return routings.filter((r) => ids.includes(r.id));
  },
);

export const sortedServerRoutingsListSelector = createSelector(
  [serverRoutingsListSelector, downloadedRoutingsListSelector],
  (serverRoutings, downloadedRoutings) => {
    const downloadedRoutesIds = downloadedRoutings.map((d) => d.id);
    const fcSortedServerRoutings = [...serverRoutings];
    fcSortedServerRoutings.sort((a, b) =>
      a.requestTime < b.requestTime ? 1 : -1,
    );
    return fcSortedServerRoutings.filter(
      (r) => !downloadedRoutesIds.includes(r.id),
    );
  },
);
