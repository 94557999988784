import React, { memo, useCallback, useState } from 'react';

import SelectFileModal from './SelectFileModal';
import ErrorModal from './ErrorModal';
import UploadModal from './UploadModal';

type Props = {
  onCancel: () => void;
};

enum Steps {
  FileChoice = 1,
  Upload,
  Error,
}

export const UploadExclusionZoneFlow: React.FunctionComponent<Props> = ({
  onCancel,
}) => {
  const [step, setStep] = useState(Steps.FileChoice);
  const [hasMultipleZonesError, setHasMultipleZonesError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validFeatures, setValidFeatures] = useState<
    GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[]
  >([]);
  const goToUpload = useCallback(
    (values: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>[]) => {
      setValidFeatures(values);
      setStep(Steps.Upload);
    },
    [],
  );

  const goToError = useCallback(
    (hasMultiple = false, error: string | undefined) => {
      setStep(Steps.Error);
      setHasMultipleZonesError(hasMultiple);
      if (error) {
        setError(error);
      }
    },
    [],
  );

  const goToFileChoice = useCallback(() => {
    setError(null);
    setHasMultipleZonesError(false);
    setStep(Steps.FileChoice);
  }, []);

  return (
    <>
      {step === Steps.FileChoice && (
        <SelectFileModal
          closeModal={onCancel}
          isOpen={step === Steps.FileChoice}
          goToUpload={goToUpload}
          goToError={goToError}
        />
      )}
      {step === Steps.Error && (
        <ErrorModal
          closeModal={onCancel}
          isOpen={step === Steps.Error}
          goToFileChoice={goToFileChoice}
          hasMultipleZonesError={hasMultipleZonesError}
          error={error}
        />
      )}
      {step === Steps.Upload && (
        <UploadModal
          closeModal={onCancel}
          isOpen={step === Steps.Upload}
          values={validFeatures}
        />
      )}
    </>
  );
};

export default memo(UploadExclusionZoneFlow);
