import React from 'react';
import { Collapse } from 'react-collapse';

import { MinusIcon, PlusIcon } from 'modules/ui/assets';
import { RegularBase } from 'modules/ui/typography';

import {
  TitleContainer,
  Container,
  ContentContainer,
  TitleColorAndIconContainer,
  TitleIconContainer,
} from './styledComponents';

type Props = {
  isOpened: boolean;
  toggleIsOpened: () => void;
  titleComponent?: React.ReactChild;
  children: React.ReactChild | React.ReactNode | React.ReactNodeArray;
  title: string;
  titleExtraComponent?: React.ReactChild;
};

const InnerCollapsibleContainer: React.FC<Props> = ({
  isOpened,
  titleComponent,
  title,
  toggleIsOpened,
  children,
  titleExtraComponent,
}) => {
  return (
    <Container isOpened={isOpened}>
      <TitleContainer onClick={toggleIsOpened}>
        <TitleIconContainer>
          <RegularBase>{title}</RegularBase>
          {titleExtraComponent}
        </TitleIconContainer>
        <TitleColorAndIconContainer>
          {titleComponent}
          {isOpened ? <MinusIcon /> : <PlusIcon />}
        </TitleColorAndIconContainer>
      </TitleContainer>
      <Collapse isOpened={isOpened}>
        <ContentContainer>{children}</ContentContainer>
      </Collapse>
    </Container>
  );
};

export default React.memo(InnerCollapsibleContainer);
